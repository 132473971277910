// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --clr-white: #E0E0E0;
  --clr-black: #1B1B3A;

  --clr-accent-light: #BCD6F0;
  --clr-accent-dark: #296EB4;

  --clr-error: red;
  --clr-success: green;

  --border-pri: 1px solid var(--clr-black);
  --border-loading-spinner: solid 5px var(--clr-white);

  --box-shadow-normal: 2px 2px 2px var(--clr-black);
  --box-shadow-small: 1px 1px 1px var(--clr-black);

  --bg-gradient: linear-gradient(127deg, var(--clr-accent-dark) 20%, var(--clr-accent-light) 80%);
}`, "",{"version":3,"sources":["webpack://./src/styles/vars.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,oBAAoB;;EAEpB,2BAA2B;EAC3B,0BAA0B;;EAE1B,gBAAgB;EAChB,oBAAoB;;EAEpB,wCAAwC;EACxC,oDAAoD;;EAEpD,iDAAiD;EACjD,gDAAgD;;EAEhD,+FAA+F;AACjG","sourcesContent":[":root {\n  --clr-white: #E0E0E0;\n  --clr-black: #1B1B3A;\n\n  --clr-accent-light: #BCD6F0;\n  --clr-accent-dark: #296EB4;\n\n  --clr-error: red;\n  --clr-success: green;\n\n  --border-pri: 1px solid var(--clr-black);\n  --border-loading-spinner: solid 5px var(--clr-white);\n\n  --box-shadow-normal: 2px 2px 2px var(--clr-black);\n  --box-shadow-small: 1px 1px 1px var(--clr-black);\n\n  --bg-gradient: linear-gradient(127deg, var(--clr-accent-dark) 20%, var(--clr-accent-light) 80%);\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
