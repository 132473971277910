// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.comment-display-wrapper {
  display: flex;
  flex-direction: row;
  gap: 10px;

  border: var(--border-pri);
  padding: 10px;
  min-width: fit-content;
}

div.comment-display-contents {
  padding: 10px;
  
  display: flex;
  flex-direction: column;
  gap: 5px;

  flex: 1;
}

div.comment-display-controls {
  
}

span.comment-display-title {
  padding: 3px;
  border-bottom: dotted 1px var(--clr-black);
  width: fit-content;

  font-weight: 300;
}

span.comment-display-body {
  padding: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/pages/home/commentsFeed/commentDisplay/CommentDisplay.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;;EAET,yBAAyB;EACzB,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,aAAa;;EAEb,aAAa;EACb,sBAAsB;EACtB,QAAQ;;EAER,OAAO;AACT;;AAEA;;AAEA;;AAEA;EACE,YAAY;EACZ,0CAA0C;EAC1C,kBAAkB;;EAElB,gBAAgB;AAClB;;AAEA;EACE,aAAa;AACf","sourcesContent":["div.comment-display-wrapper {\n  display: flex;\n  flex-direction: row;\n  gap: 10px;\n\n  border: var(--border-pri);\n  padding: 10px;\n  min-width: fit-content;\n}\n\ndiv.comment-display-contents {\n  padding: 10px;\n  \n  display: flex;\n  flex-direction: column;\n  gap: 5px;\n\n  flex: 1;\n}\n\ndiv.comment-display-controls {\n  \n}\n\nspan.comment-display-title {\n  padding: 3px;\n  border-bottom: dotted 1px var(--clr-black);\n  width: fit-content;\n\n  font-weight: 300;\n}\n\nspan.comment-display-body {\n  padding: 10px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
