// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.standard-form-labeled-input-wrapper {
  position: relative;
  width: 100%;
}

div.standard-form-labeled-input-wrapper>label {
  user-select: none;
  position: absolute;
  top: -0.7em;
  left: 0.5em;
  background-color: var(--clr-white);
  padding: 0 5px;
  text-transform: capitalize;
  max-width: 100%;
  overflow: hidden;
}

div.standard-form-labeled-input-wrapper>input, div.standard-form-labeled-input-wrapper>textarea {
  border: var(--border-pri);
  padding: 10px;
  max-width: 100%;
}

div.standard-form-labeled-input-wrapper>input:hover, div.standard-form-labeled-input-wrapper>input:focus,
div.standard-form-labeled-input-wrapper>textarea:hover, div.standard-form-labeled-input-wrapper>textarea:focus {
  color: var(--clr-accent-dark);
  box-shadow: var(--box-shadow-normal);
}`, "",{"version":3,"sources":["webpack://./src/components/forms/standardFormLabeledInput/StandardFormLabeledInput.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,WAAW;AACb;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,WAAW;EACX,WAAW;EACX,kCAAkC;EAClC,cAAc;EACd,0BAA0B;EAC1B,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;EACzB,aAAa;EACb,eAAe;AACjB;;AAEA;;EAEE,6BAA6B;EAC7B,oCAAoC;AACtC","sourcesContent":["div.standard-form-labeled-input-wrapper {\n  position: relative;\n  width: 100%;\n}\n\ndiv.standard-form-labeled-input-wrapper>label {\n  user-select: none;\n  position: absolute;\n  top: -0.7em;\n  left: 0.5em;\n  background-color: var(--clr-white);\n  padding: 0 5px;\n  text-transform: capitalize;\n  max-width: 100%;\n  overflow: hidden;\n}\n\ndiv.standard-form-labeled-input-wrapper>input, div.standard-form-labeled-input-wrapper>textarea {\n  border: var(--border-pri);\n  padding: 10px;\n  max-width: 100%;\n}\n\ndiv.standard-form-labeled-input-wrapper>input:hover, div.standard-form-labeled-input-wrapper>input:focus,\ndiv.standard-form-labeled-input-wrapper>textarea:hover, div.standard-form-labeled-input-wrapper>textarea:focus {\n  color: var(--clr-accent-dark);\n  box-shadow: var(--box-shadow-normal);\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
