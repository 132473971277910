// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html {
  color: var(--clr-black);
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

body {
  background: var(--clr-accent-dark);
  background: var(--bg-gradient);
  background-attachment: fixed;
  min-height: 100vh;
}

main {
  background-color: var(--clr-white);
  min-width: min(600px, 90%);
  max-width: min(980px, 90%);
  margin: 30px auto;
  padding: 20px;

  min-height: 400px;

  box-shadow: var(--box-shadow-normal);
}

h1 {
  text-align: center;
  display: block;
  width: 100%;
  font-size: 1.7em;
  font-weight: 700;
}

span.center {
  display: block;
  text-align: center;
}

.error {
  color: var(--clr-error);
}

.success {
  color: var(--clr-success);
}

.heavy {
  font-weight: 700;
}

@media (max-width: 980px) {
  :root {
    font-size: 1.5rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/styles/global.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,mJAAmJ;AACrJ;;AAEA;EACE,kCAAkC;EAClC,8BAA8B;EAC9B,4BAA4B;EAC5B,iBAAiB;AACnB;;AAEA;EACE,kCAAkC;EAClC,0BAA0B;EAC1B,0BAA0B;EAC1B,iBAAiB;EACjB,aAAa;;EAEb,iBAAiB;;EAEjB,oCAAoC;AACtC;;AAEA;EACE,kBAAkB;EAClB,cAAc;EACd,WAAW;EACX,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,kBAAkB;AACpB;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE;IACE,iBAAiB;EACnB;AACF","sourcesContent":["html {\n  color: var(--clr-black);\n  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;\n}\n\nbody {\n  background: var(--clr-accent-dark);\n  background: var(--bg-gradient);\n  background-attachment: fixed;\n  min-height: 100vh;\n}\n\nmain {\n  background-color: var(--clr-white);\n  min-width: min(600px, 90%);\n  max-width: min(980px, 90%);\n  margin: 30px auto;\n  padding: 20px;\n\n  min-height: 400px;\n\n  box-shadow: var(--box-shadow-normal);\n}\n\nh1 {\n  text-align: center;\n  display: block;\n  width: 100%;\n  font-size: 1.7em;\n  font-weight: 700;\n}\n\nspan.center {\n  display: block;\n  text-align: center;\n}\n\n.error {\n  color: var(--clr-error);\n}\n\n.success {\n  color: var(--clr-success);\n}\n\n.heavy {\n  font-weight: 700;\n}\n\n@media (max-width: 980px) {\n  :root {\n    font-size: 1.5rem;\n  }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
