// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `nav.primary-nav-wrapper {
  background-color: var(--clr-black);
  color: var(--clr-white);
}

div.primary-nav-inner-wrapper {
  max-width: 980px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: start;
  padding: 10px 20px 0px 10px;

  margin: auto;
}

nav.primary-nav-wrapper ul {
  display: flex;
  flex-direction: row;
  gap: 30px;
  height: 100%;
}

nav.primary-nav-wrapper a {
  color: var(--clr-white);
  user-select: none;
}

nav.primary-nav-wrapper a>span {
  /* padding: 5px; */
}

nav.primary-nav-wrapper a.active {
  color: var(--clr-accent-light);
}

div.nav-link-wrapper {
  display: flex;
  flex-direction: column;
}

div.nav-link-wrapper>span {
  padding-bottom: 5px;
}

div.nav-link-wrapper::after {
  content: '';
}

nav.primary-nav-wrapper a.active>div.nav-link-wrapper::after {
  background: var(--bg-gradient);
  background-attachment: fixed;
  height: 5px;
}

nav.primary-nav-wrapper a:hover, nav.primary-nav-wrapper a:focus {
  color: var(--clr-accent-light);
}`, "",{"version":3,"sources":["webpack://./src/components/nav/primaryNav/PrimaryNav.css"],"names":[],"mappings":"AAAA;EACE,kCAAkC;EAClC,uBAAuB;AACzB;;AAEA;EACE,gBAAgB;;EAEhB,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,kBAAkB;EAClB,2BAA2B;;EAE3B,YAAY;AACd;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;EACT,YAAY;AACd;;AAEA;EACE,uBAAuB;EACvB,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,8BAA8B;EAC9B,4BAA4B;EAC5B,WAAW;AACb;;AAEA;EACE,8BAA8B;AAChC","sourcesContent":["nav.primary-nav-wrapper {\n  background-color: var(--clr-black);\n  color: var(--clr-white);\n}\n\ndiv.primary-nav-inner-wrapper {\n  max-width: 980px;\n\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  align-items: start;\n  padding: 10px 20px 0px 10px;\n\n  margin: auto;\n}\n\nnav.primary-nav-wrapper ul {\n  display: flex;\n  flex-direction: row;\n  gap: 30px;\n  height: 100%;\n}\n\nnav.primary-nav-wrapper a {\n  color: var(--clr-white);\n  user-select: none;\n}\n\nnav.primary-nav-wrapper a>span {\n  /* padding: 5px; */\n}\n\nnav.primary-nav-wrapper a.active {\n  color: var(--clr-accent-light);\n}\n\ndiv.nav-link-wrapper {\n  display: flex;\n  flex-direction: column;\n}\n\ndiv.nav-link-wrapper>span {\n  padding-bottom: 5px;\n}\n\ndiv.nav-link-wrapper::after {\n  content: '';\n}\n\nnav.primary-nav-wrapper a.active>div.nav-link-wrapper::after {\n  background: var(--bg-gradient);\n  background-attachment: fixed;\n  height: 5px;\n}\n\nnav.primary-nav-wrapper a:hover, nav.primary-nav-wrapper a:focus {\n  color: var(--clr-accent-light);\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
