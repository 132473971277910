// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `form.standard-form {
  padding: 20px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  gap: 20px;

  width: fit-content;
  max-width: 100%;
  margin: auto;
}

div.standard-form-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  width: 100%;
}

input.standard-input {
  border: var(--border-pri);
  padding: 5px 10px;
}

input.standard-input:hover, input.standard-input:focus {
  color: var(--clr-accent-dark);
  box-shadow: var(--box-shadow-normal);
}

button.standard-button {
  border: var(--border-pri);
  padding: 4px 8px;
  text-transform: capitalize;

  user-select: none;
}

button.standard-button:hover, button.standard-button:focus {
  box-shadow: var(--box-shadow-normal);
  color: var(--clr-accent-dark);
}

button.standard-button:active {
  scale: 0.95;
  box-shadow: var(--box-shadow-small);
}`, "",{"version":3,"sources":["webpack://./src/styles/forms.css"],"names":[],"mappings":"AAAA;EACE,aAAa;;EAEb,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;;EAEnB,SAAS;;EAET,kBAAkB;EAClB,eAAe;EACf,YAAY;AACd;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,SAAS;EACT,WAAW;AACb;;AAEA;EACE,yBAAyB;EACzB,iBAAiB;AACnB;;AAEA;EACE,6BAA6B;EAC7B,oCAAoC;AACtC;;AAEA;EACE,yBAAyB;EACzB,gBAAgB;EAChB,0BAA0B;;EAE1B,iBAAiB;AACnB;;AAEA;EACE,oCAAoC;EACpC,6BAA6B;AAC/B;;AAEA;EACE,WAAW;EACX,mCAAmC;AACrC","sourcesContent":["form.standard-form {\n  padding: 20px;\n\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n\n  gap: 20px;\n\n  width: fit-content;\n  max-width: 100%;\n  margin: auto;\n}\n\ndiv.standard-form-row {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  gap: 10px;\n  width: 100%;\n}\n\ninput.standard-input {\n  border: var(--border-pri);\n  padding: 5px 10px;\n}\n\ninput.standard-input:hover, input.standard-input:focus {\n  color: var(--clr-accent-dark);\n  box-shadow: var(--box-shadow-normal);\n}\n\nbutton.standard-button {\n  border: var(--border-pri);\n  padding: 4px 8px;\n  text-transform: capitalize;\n\n  user-select: none;\n}\n\nbutton.standard-button:hover, button.standard-button:focus {\n  box-shadow: var(--box-shadow-normal);\n  color: var(--clr-accent-dark);\n}\n\nbutton.standard-button:active {\n  scale: 0.95;\n  box-shadow: var(--box-shadow-small);\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
